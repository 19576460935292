import React, { useEffect, useRef } from 'react';
import Phaser from 'phaser';

const PhaserGame = () => {
  const gameRef = useRef(null);

  useEffect(() => {
    const config = {
      type: Phaser.AUTO,
      width: 800,
      height: 600,
      parent: gameRef.current,
      scene: {
        preload: preload,
        create: create,
        update: update
      },
      physics: {
        default: 'arcade',
        arcade: {
          gravity: { y: 0 },
          debug: false
        }
      }
    };

    const game = new Phaser.Game(config);

    function preload() {
      this.load.tilemapTiledJSON('map', 'assets/map.json');
      this.load.image('tiles', 'assets/tileset.png');
    }

    function create() {
      const map = this.make.tilemap({ key: 'map' });
      const tileset = map.addTilesetImage('tilesetName', 'tiles');
      
      const groundLayer = map.createLayer('ground', tileset, 0, 0);
      const objectLayer = map.createLayer('objects', tileset, 0, 0);

      groundLayer.setCollisionByProperty({ collides: true });

      this.player = this.physics.add.sprite(400, 300, 'player');
      this.player.setCollideWorldBounds(true);

      this.cameras.main.setBounds(0, 0, map.widthInPixels, map.heightInPixels);
      this.cameras.main.startFollow(this.player, true);

      this.input.on('pointerdown', this.handleTouchStart, this);
      this.input.on('pointermove', this.handleTouchMove, this);
      this.input.on('pointerup', this.handleTouchEnd, this);

      this.touchActive = false;
      this.touchTarget = new Phaser.Math.Vector2();

      this.physics.add.collider(this.player, groundLayer);
    }

    function update() {
      const speed = 200;

      if (this.touchActive) {
        const worldPoint = this.cameras.main.getWorldPoint(this.touchTarget.x, this.touchTarget.y);
        
        const angle = Phaser.Math.Angle.Between(
          this.player.x, this.player.y,
          worldPoint.x, worldPoint.y
        );
        this.player.setVelocity(
          Math.cos(angle) * speed,
          Math.sin(angle) * speed
        );
      } else {
        this.player.setVelocity(0, 0);
      }
    }

    function handleTouchStart(pointer) {
      this.touchActive = true;
      this.touchTarget.set(pointer.x, pointer.y);
    }

    function handleTouchMove(pointer) {
      if (this.touchActive) {
        this.touchTarget.set(pointer.x, pointer.y);
      }
    }

    function handleTouchEnd() {
      this.touchActive = false;
      this.player.setVelocity(0, 0);
    }

    return () => {
      game.destroy(true);
    };
  }, []);

  return <div ref={gameRef} />;
};

export default PhaserGame;