import React, { useEffect } from 'react';

const TelegramMiniApp = () => {
  useEffect(() => {
    const initTelegramApp = () => {
      if (window.Telegram && window.Telegram.WebApp) {
        const webapp = window.Telegram.WebApp;
        webapp.ready();
        
        // You can access user data like this:
        const user = webapp.initDataUnsafe.user;
        console.log('Telegram user:', user);

        // You can also use other Telegram Mini App features here
        webapp.MainButton.setText('Hello Telegram!');
        webapp.MainButton.show();
      } else {
        console.error('Telegram WebApp is not available');
      }
    };

    initTelegramApp();
  }, []);

  return (
    <div>
      <h1>Welcome to Telegram Mini App</h1>
      <p>This page initializes the Telegram Mini App.</p>
    </div>
  );
};

export default TelegramMiniApp;